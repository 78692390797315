import { createReducer, on } from '@ngrx/store';
import { BasicInfoComponent } from 'app/ehs/dashboard/_shared/components/basic-info/basic-info.component';
import {
  InspectionSchedulingApiActions,
  InspectionSchedulingEditPageActions
} from '../actions';
import { InspectionScheduling } from '../models/inspection-scheduling.model';

export const featureKey = 'editing';

export type State = InspectionScheduling | null;

export const initialState: State = null;

export const reducer = createReducer(
  initialState,
  on(
    InspectionSchedulingEditPageActions.updateBasicInfo,
    (state, { basicInfo }) => ({ ...state, basicInfo })
  ),
  on(
    InspectionSchedulingEditPageActions.updateInspectionFormList,
    (state, { inspectionFormList }) => ({ ...state, inspectionFormList })
  ),
  on(
    InspectionSchedulingApiActions.saveAsDraftSuccess,
    (state, { inspectionId }) => {
      state.basicInfo.id = inspectionId;
      return state;
    }
  ),
  on(
    InspectionSchedulingApiActions.getDraftDetailSuccess,
    (state, { res }) => {
      console.log('reducer res', res)
      return {
      ...state,
      status:res.status,
      inspectionFormList: res.inspectionFormList,
      basicInfo: { id: res.id, org: res.org, inspectionType: res.inspectionType,comments:res.comments,changeTypeList:res.changeTypeList,applyId:res.applyDTO }
    }}
  ),
  on(
    // 清除
    InspectionSchedulingEditPageActions.leave,
    (state) => ({
      ...initialState
    })
  ),
);
