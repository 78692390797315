import { createAction, props } from '@ngrx/store';
import { InspectionBasicInfo, InspectionForm } from '../../_shared/models';

// set isPending to true
export const setEditPageIsPendingToTrue = createAction(
  '[Ad-hoc Inspection Edit Page1225] Set changeEdit Page isPending To True'
);

export const setEditPageIsPendingToFalse = createAction(
  '[Ad-hoc Inspection Edit Page1225] Set changeEdit Page isPending To False'
);

export const updateBasicInfo = createAction(
  '[Issue Reporting Edit Page1225] Update changeBasic Info',
  props<{ basicInfo: InspectionBasicInfo }>()
);

export const updateInspectionFormList = createAction(
  '[Issue Reporting Edit Page1225] Update changeInspection Result',
  props<{ inspectionFormList: InspectionForm[] }>()
);

export const leave = createAction(
  '[Ad-hoc Inspection Edit Page1225] changeLeave'
);
