import { createReducer, on } from '@ngrx/store';
import { AdHocInspectionEditPageActions } from '../actions';

export const featureKey = 'pending123';

export type State = boolean;

export const initialState: State = false;

export const reducer = createReducer(
  initialState,
  on(
    // set isPending to true
    AdHocInspectionEditPageActions.setEditPageIsPendingToTrue,
    state => (true)
  ),
  on(
    AdHocInspectionEditPageActions.setEditPageIsPendingToFalse,
    state => false
  ),
);

