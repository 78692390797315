import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromEditing from './ad-hoc-inspection-editing.reducer';
import * as fromPending from './pending.reducer';

export const featureKey = 'adHocInspection';

export interface AdHocInspectionState {
  [fromEditing.featureKey]: fromEditing.State;
  [fromPending.featureKey]: fromPending.State;
}

export interface State {
  [featureKey]: AdHocInspectionState;
}

export function reducers(state: AdHocInspectionState | undefined, action: Action) {
  return combineReducers({
    [fromEditing.featureKey]: fromEditing.reducer,
    [fromPending.featureKey]: fromPending.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('inspection');
export const getAdHocInspectionState = createSelector(
  getParentState,
  state => state[featureKey]
);

// Pending
// ----------------------------------------------------------------------------

export const getIsPending = createSelector(
  getAdHocInspectionState,
  state => state.pending123
);

// AdHoc Inspection
// ----------------------------------------------------------------------------

export const getEditingAdHocInspection = createSelector(
  getAdHocInspectionState,
  state => state.editing222
);
