import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromEditing from './inspection-scheduling-editing.reducer';
import * as fromPending from './pending.reducer';
import * as fromClaimable from './claimable.reducer';
export const featureKey = 'changeInspection';

export interface InspectionSchedulingState {
  [fromEditing.featureKey]: fromEditing.State;
  [fromPending.featureKey]: fromPending.State;
  [fromClaimable.featureKey]: fromClaimable.State;
}

export interface State {
  [featureKey]: InspectionSchedulingState;
}

export function reducers(state: InspectionSchedulingState | undefined, action: Action) {
  return combineReducers({
    [fromEditing.featureKey]: fromEditing.reducer,
    [fromPending.featureKey]: fromPending.reducer,
    [fromClaimable.featureKey]: fromClaimable.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('inspection');
export const getAdHocInspectionState = createSelector(
  getParentState,
  state => state[featureKey]
);

// Pending
// ----------------------------------------------------------------------------

export const getIsPending = createSelector(
  getAdHocInspectionState,
  state => state.pending
);

// AdHoc Inspection
// ----------------------------------------------------------------------------

export const getEditingAdHocInspection = createSelector(
  getAdHocInspectionState,
  state => state.editing
);

export const getChangeTypes = createSelector(
  getAdHocInspectionState,
  state => state[featureKey]
);


export const getClaimable = createSelector(
  getAdHocInspectionState,
  state => state.claimable
);
