import { props, createAction } from '@ngrx/store';
import { InspectionScheduling } from '../models/inspection-scheduling.model';

// 新增巡檢回報
export const addAdHocInspection = createAction(
  '[Ad-hoc Inspection/API1] Add changeAd-hoc Inspection',
);

export const addAdHocInspectionSuccess = createAction(
  '[Ad-Hoc Inspection/API2] Add changeAd-hoc Inspection Success',
  props<{ adHocInspectionId: number }>()
);

export const addAdHocInspectionFailure = createAction(
  '[Ad-Hoc Inspection/API3] Add changeAd-hoc Inspection Failure',
  props<{ res: any }>()
);

// 保存草稿
export const saveAsDraft = createAction(
  '[Ad-hoc Inspection/API4] Add changeDraft Ad-hoc Inspection',
);

export const saveAsDraftSuccess = createAction(
  '[Ad-Hoc Inspection/API5] Add changeDraft Ad-hoc Inspection Success',
  props<{ inspectionId: any }>()
);

export const saveAsDraftFailure = createAction(
  '[Ad-Hoc Inspection/API6] Add changeDraft Ad-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 获得草稿内容
export const getDraftDetail = createAction(
  '[Ad-hoc Inspection/API1111] Get changeDraft Ad-hoc Inspection',
  props<{ taskId: any }>()
);

export const getDraftDetailSuccess = createAction(
  '[Ad-Hoc Inspection/API222] Get changeDraft Ad-hoc Inspection Success',
  props<{ res: any }>()
);

export const getDraftDetailFailure = createAction(
  '[Ad-Hoc Inspection/API333] Get changeDraft Ad-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 立即检查周表检查计算
export const calculate = createAction(
  '[Ad-hoc Inspection/API] calculate changeAd-hoc Inspection',
  props<{ startDate: any, endDate: any, org_id: any }>()
);

export const calculateSuccess = createAction(
  '[Ad-Hoc Inspection/API] calculate changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const calculateFailure = createAction(
  '[Ad-Hoc Inspection/API] calculate changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 变更类型
export const changeTypes = createAction(
  '[Ad-hoc Inspection/API23] changeTypes changeAd-hoc Inspection',
);

export const changeTypesSuccess = createAction(
  '[Ad-Hoc Inspection/API] changeTypes changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const changeTypesFailure = createAction(
  '[Ad-Hoc Inspection/API] changeTypes changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 默认申请人
export const getInitInfo = createAction(
  '[Ad-hoc Inspection/API231230] getInitInfo changeAd-hoc Inspection',
);

export const getInitInfoSuccess = createAction(
  '[Ad-Hoc Inspection/API1230] getInitInfo changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const getInitInfoFailure = createAction(
  '[Ad-Hoc Inspection/API1230] getInitInfo changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 获取表格
export const changeTable = createAction(
  '[Ad-hoc Inspection/API1] changeTable changeAd-hoc Inspection',
  props<{ orgId:any }>()
);

export const changeTableSuccess = createAction(
  '[Ad-Hoc Inspection/API2] changeTable changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const changeTableFailure = createAction(
  '[Ad-Hoc Inspection/API3] changeTable changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);
// 取得任務是否能被認領
export const loadInspectionTaskClaimable = createAction(
  '[Inspection Corrective Action/API11] Load changeInspectionReport Task Claimable',
  props<{ id: number }>()
);

export const loadInspectionTaskClaimableSuccess = createAction(
  '[Inspection Corrective Action/API111] Load changeInspectionReport Task Claimable Success',
  props<{ data: boolean }>()
);

export const loadInspectionTaskClaimableFailure = createAction(
  '[Inspection Corrective Action/API1111] Load changeInspectionReport Task Claimable Failure',
  props<{ res: any }>()
);
// 認領任務
export const claimInspectionTask = createAction(
  '[Inspection Corrective Action/API1111] ClaimReport changeInspection Task',
  props<{ id: number }>()
);

export const claimInspectionTaskSuccess = createAction(
  '[Inspection Corrective Action/API122] ClaimReport changeInspection Task Success',
  props<{ data: any}>()
);

export const claimInspectionTaskFailure = createAction(
  '[Inspection Corrective Action/API222] ClaimReport changeInspection Task Failure',
  props<{ res: any }>()
);
