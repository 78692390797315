import { createReducer, on } from '@ngrx/store';
import {   InspectionSchedulingApiActions,
  InspectionSchedulingEditPageActions,} from '../actions';

export const featureKey = 'claimable';

export type State = boolean;

export const initialState: State = false;

export const reducer = createReducer(
  initialState,
  on(
    InspectionSchedulingApiActions.claimInspectionTaskSuccess,
    (state, { data }) => data
  )
);
