import { props, createAction } from '@ngrx/store';
import { AdHocInspection } from '../models/ad-hoc-inspection.model';

// 新增巡檢回報
export const addAdHocInspection = createAction(
  '[Ad-hoc Inspection/APIchange1225] Add1 changeAd-hoc Inspection',
);

export const addAdHocInspectionSuccess = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Add1 changeAd-hoc Inspection Success',
  props<{ adHocInspectionId: number }>()
);

export const addAdHocInspectionFailure = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Add1 changeAd-hoc Inspection Failure',
  props<{ res: any }>()
);

// 保存草稿
export const saveAsDraft = createAction(
  '[Ad-hoc Inspection/APIchange1225] Add2 changeDraft Ad-hoc Inspection',
);

export const saveAsDraftSuccess = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Add2 changeDraft Ad-hoc Inspection Success',
  props<{ inspectionId: any }>()
);

export const saveAsDraftFailure = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Add2 changeDraft Ad-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 获得草稿内容
export const getDraftDetail = createAction(
  '[Ad-hoc Inspection/APIchange1225] Get3 changeDraft Ad-hoc Inspection',
  props<{ taskId: any }>()
);

export const getDraftDetailSuccess = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Get3 changeDraft Ad-hoc Inspection Success',
  props<{ res: any }>()
);

export const getDraftDetailFailure = createAction(
  '[Ad-Hoc Inspection/APIchange1225] Get3 changeDraft Ad-hoc Inspection Failure',
  props<{ msg: string }>()
);

// 立即检查周表检查计算
export const calculate = createAction(
  '[Ad-hoc Inspection/APIchange1225] calculate changeAd-hoc Inspection',
  props<{ startDate: any, endDate: any, org_id: any }>()
);

export const calculateSuccess = createAction(
  '[Ad-Hoc Inspection/APIchange1225] calculate changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const calculateFailure = createAction(
  '[Ad-Hoc Inspection/APIchange1225] calculate changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);


// 获取表格
export const changeTable = createAction(
  '[Ad-hoc Inspection/API11] changeTableAd11 changeAd-hoc Inspection',
  props<{ orgId:any }>()
);

export const changeTableSuccess = createAction(
  '[Ad-Hoc Inspection/API22] changeTableAd11 changeAd-hoc Inspection Success',
  props<{ res: any }>()
);

export const changeTableFailure = createAction(
  '[Ad-Hoc Inspection/API33] changeTableAd11 changeAd-hoc Inspection Failure',
  props<{ msg: string }>()
);
